<template>
  <div class="outpatient_detail_container">
    <van-popup class="popup" v-model="popup" style="width: 70%;">
      <div class="con">
        温馨提示：公众号暂时无法医保结算，请到收费窗口或自助机缴费！
      </div>
      <div class="button">
        <van-button @click="conf()" type="primary">确认</van-button>
      </div>
    </van-popup>
    <div class="header">
      <van-notice-bar
        mode="closeable"
        wrapable
        color="#1989fa"
        style="font-size: 18px;"
        background="#ecf9ff"
        left-icon="info-o"
        >温馨提示：如需要就诊消息推送，请先关注“台山市人民医院”公众号</van-notice-bar
      >
      <div class="info">
        <div>科室：{{ clinicFeeDetail.deptName }}</div>
        <div>医生：{{ clinicFeeDetail.doctorName }}</div>
        <div>就诊人：{{ clinicFeeDetail.patName }}</div>
        <div>就诊时间：{{ clinicFeeDetail.diagnTime }}</div>
      </div>
      <div class="cost">
        <div>总金额：{{ clinicFeeDetail.totalAmout }}元</div>
        <div>
          <span>应交金额：</span>
          <span style="color:red">{{ clinicFeeDetail.budgeting }}</span
          >元
        </div>
      </div>
      <div class="point">
        <div class="point-title">选择支付方式</div>
        <div class="item">
          <van-radio-group v-model="radio">
            <van-cell-group>
              <van-cell clickable data-name="1" @click="onClickType">
                <div
                  slot="title"
                  style="display: flex; align-items: center; justify-content: flex-start;"
                >
                  <van-image
                    fit="contain"
                    style="height: 30px;width: 40px; margin-right: 5px"
                    src="https://xheryuanapp.xh2ph.com:8443/wxxcx/wx/wechat.png"
                  />
                  <div class="van-cell-text">微信支付</div>
                </div>
                <van-radio slot="right-icon" name="1" checked-color="#67C23A" />
              </van-cell>

              <van-cell
                clickable
                data-name="3"
                @click="onClickType"
                v-if="patIdNo"
              >
                <div
                  slot="title"
                  style="display: flex; align-items: center;justify-content: flex-start;"
                >
                  <van-image
                    fit="contain"
                    style="height: 30px; width: 40px; margin-right: 5px;"
                    src="https://xheryuanapp.xh2ph.com:8443/wxxcx/wx/chs.png"
                  />
                  <div class="van-cell-text">医保支付</div>
                </div>
                <van-radio slot="right-icon" name="3" checked-color="#67C23A" />
              </van-cell>
            </van-cell-group>
          </van-radio-group>
        </div>
      </div>
    </div>

    <div class="detail">
      <div class="title">收费明细</div>
      <div class="list">
        <van-cell
          v-for="(it, index) in clinicFeeDetail.item"
          :key="index"
          :title="it.itemTotalFee + '元'"
          :value="'x' + it.itemNumber + '/' + it.itemUnit"
          :label="it.itemName"
        />
      </div>
    </div>
    <van-button type="primary" block class="btn" @click="orderBtn"
      >支&nbsp;&nbsp;&nbsp;付</van-button
    >
  </div>
</template>

<script>
import { jsonPost, formPost } from "@/baseAPI";
import qs from "qs";
export default {
  name: "Detail",
  data() {
    return {
      orderNum: "",
      patCardNo: "",
      patCardType: "",
      idCard: "",
      patIdNo: false,
      clinicFeeDetail: {},
      payData: {},
      popup: false,
      radio: "1"
    };
  },
  created() {
    if (this.$route.query.orderNum) {
      this.orderNum = this.$route.query.orderNum;
      this.idCard = this.$route.query.idCard;
      this.patCardNo = this.$route.query.patCardNo;
      this.patCardType = this.$route.query.patCardType;
    } else {
      this.orderNum = window.localStorage.getItem("orderNum");
      this.idCard = window.localStorage.getItem("idCard");
      this.patCardNo = window.localStorage.getItem("patCardNo");
      this.patCardType = window.localStorage.getItem("patCardType");

      window.localStorage.removeItem("orderNum");
      window.localStorage.removeItem("patCardNo");
      window.localStorage.removeItem("patCardType");
      window.localStorage.removeItem("idCard");
    }
    window.localStorage.setItem("orderNum", this.orderNum);
    window.localStorage.setItem("patCardNo", this.patCardNo);
    window.localStorage.setItem("idCard", this.idCard);
    window.localStorage.setItem("patCardType", this.patCardType);

    if (window.localStorage.getItem("openId") === null) {
      this.getCode();
    }
    console.log("初始化,yb");
    this.getClinicFeeDetail();
  },
  methods: {
    onClickType(event) {
      const { name } = event.currentTarget.dataset;
      console.log(this.radio);
      if (this.clinicFeeDetail.yblb == "0" && name == "3") {
        this.$dialog.alert({
          message: "当前处方是自费处方，无法医保支付，如需更改请咨询医生"
        });
      } else if (this.clinicFeeDetail.yblb == "1" && name == "1") {
        this.$dialog.alert({
          message: "当前是医保处方，确定自费支付吗？"
        });
      } else {
        this.radio = name;
        if (this.radio == "3") {
          this.$dialog.alert({
            message: "非本人不支持医保支付"
          });
        }
      }
    },
    // 获取code
    getCode() {
      let local = window.location.href;
      let appid = "wxcdae494f7e9422e7";
      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(
        local
      )}&response_type=code&scope=snsapi_base&state=123&#wechat_redirect`;
      // window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxcdae494f7e9422e7&redirect_uri=http://tssywx.tssrmyy.com&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect"
      let str = window.location.href;
      let code = str.split("code=")[1].split("&")[0];
      this.code = code;
      this.login(code);
    },
    //获取openId
    async login(code) {
      this.$toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true
      });
      let data = qs.stringify({
        code: code
      });
      const { data: res } = await formPost("/wxapp/login", data);
      this.$toast.clear();
      if (res.code === 0) {
        window.localStorage.setItem("openId", res.data.openid);
        this.getClinicFeeDetail();
      } else {
        this.$toast.fail(res.msg);
      }
    },
    //获取门诊待缴费详情
    async getClinicFeeDetail() {
      console.log("获取门诊待缴费详情");
      this.$toast.loading({
        duration: 0,
        message: "加载中",
        forbidClick: true
      });
      const { data: res } = await jsonPost(
        "/wxapp/outpatient/getClinicFeeDetail",
        {
          orderNum: this.orderNum,
          patCardType: this.patCardType,
          patCardNo: this.patCardNo,
          idCard: this.idCard,
          acctuseflag: "1"
        }
      );
      this.$toast.clear();
      if (res.code === 0) {
        this.clinicFeeDetail = res.data;
        if (res.data.patIdNo !== null && res.data.patIdNo !== undefined) {
          window.localStorage.setItem("idCard", res.data.patIdNo);
        }
        if (res.data.yblb == "0") {
          this.radio = "1";
        } else if (
          res.data.yblb == "1" &&
          res.data.patIdNo !== null &&
          res.data.patIdNo !== undefined
        ) {
          this.radio = "3";
          this.patIdNo = true;
        }
        console.log(res.data);
      } else if (res.code === 500 && res.msg === "暂不支持医保结算!") {
        this.popup = true;
      } else {
        this.$toast.fail(res.msg);
      }
    },
    conf() {
      this.$router.push({
        path: "/"
      });
    },
    //门诊下单
    async order() {
      console.log(this.radio);

      let url = "";
      if (this.radio == "2" && this.clinicFeeDetail.return2206 !== "") {
        url = "/wxapp/outpatient/medicalInsuranceOutpatientPayUnifiedOrder";
      } else if (this.radio == "1") {
        url = "/wxapp/outpatient/outpatientPayUnifiedOrder";
      }
      this.$toast.loading({
        duration: 0,
        message: "加载中",
        forbidClick: true
      });
      //选择（统筹+现金）或者（统筹+个账）且 return2206 不为空时支付 recordType 传9
      //其他状态传8
      let rType = "";
      if (this.radio == "1") {
        rType = "8";
        const { data: res } = await jsonPost(url, {
          cost: this.clinicFeeDetail.totalAmout,
          openId: window.localStorage.getItem("openId"),
          orderNum: this.orderNum,
          patCardNo: this.patCardNo,
          patCardType: this.patCardType,
          recordType: rType,
          patName: this.clinicFeeDetail.patName,
          doctorName: this.clinicFeeDetail.doctorName,
          deptName: this.clinicFeeDetail.deptName,
          budgeting:
            this.radio == "1" ? undefined : this.clinicFeeDetail.budgeting,
          medicalInsurance:
            this.radio == "1"
              ? undefined
              : this.clinicFeeDetail.medicalInsurance,
          return2206:
            this.radio == "1" ? undefined : this.clinicFeeDetail.return2206,
          medicalInsuranceType: this.radio - 1,
          // medicalInsuranceHospitalType: this.clinicFeeDetail.medicalInsuranceHospitalType
          medicalInsuranceHospitalType: "1"
        });
        this.$toast.clear();
        if (res.code === 0) {
          console.log(res);
          this.payData = res.data;
          this.pay();
        } else {
          this.$toast.fail(res.msg);
        }
      } else if (this.radio == "3") {
        this.$toast.clear();
        window.localStorage.setItem("orderNum", this.orderNum);
        // 机构渠道认证编码
        // let orgChnlCrtfCodg = 'BqK1kMStlhVDgN2uHf4EsLK/F2LjZPYJ81nK2eYQqxu+6IO2JiR4I6RlbboEeY9H'
        // let orgCodg = 'H44078100002' //定点医药机构编码
        // // 测试环境
        // let orgAppId = '1H56DA8H609O3F60C80A000006717D05' //定点医药机构应用ID
        // let appid = "wxcdae494f7e9422e7"
        // let channel = 'AAE04h0QalhWndXLQb3mz65H' //渠道号
        // let local = "http://tssywx.tssrmyy.com/csyb/outpatient/medical";

        //window.location.href = "https://mitest.wecity.qq.com/oauth/code?authType=2&isDepart=2&bizType=04107&appid=wxcdae494f7e9422e7&cityCode=440781&channel=AAE04h0QalhWndXLQb3mz65H&orgChnlCrtfCodg=BqK1kMStlhVDgN2uHf4EsLK%2FF2LjZPYJ81nK2eYQqxu%2B6IO2JiR4I6RlbboEeY9H&orgCodg=H44078100002&orgAppId=1H56DA8H609O3F60C80A000006717D05&redirectUrl=http%3A%2F%2Ftssywx.tssrmyy.com%2Fcsyb%2F%23%2Foutpatient%2Fmedical"
        window.location.href =
          "https://card.wecity.qq.com/oauth/code?authType=2&isDepart=2&bizType=04107&appid=wxcdae494f7e9422e7&cityCode=440781&channel=AAE04h0QalhWndXLQb3mz65H&orgChnlCrtfCodg=BqK1kMStlhVDgN2uHf4EsLK%2FF2LjZPYJ81nK2eYQqxu%2B6IO2JiR4I6RlbboEeY9H&orgCodg=H44078100002&orgAppId=1HFR1294704K75430B0A000040D03343&redirectUrl=http%3A%2F%2Ftssywx.tssrmyy.com%2Fwsyb%2F%23%2Foutpatient%2Fmedical";
      }
    },
    // 调起微信支付
    pay() {
      let that = this;
      function onBridgeReady() {
        WeixinJSBridge.invoke(
          "getBrandWCPayRequest",
          {
            appId: "wxcdae494f7e9422e7",
            timeStamp: that.payData.timeStamp,
            nonceStr: that.payData.nonceStr,
            package: that.payData.package,
            signType: that.payData.signType,
            paySign: that.payData.paySign
          },
          function(res) {
            if (res.err_msg === "get_brand_wcpay_request:ok") {
              that.$router.push({
                path: "/outpatient/pay",
                query: {
                  outTradeNo: that.payData.outTradeNo,
                  patCardNo: that.patCardNo
                }
              });
            } else if (res.err_msg === "get_brand_wcpay_request:cancel") {
              that.$toast.fail({
                message: "支付未完成"
              });
              setTimeout(() => {
                that.getClinicFeeDetail();
              }, 2000);
            } else {
              that.$toast.fail(res.err_msg);
            }
          }
        );
      }
      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener(
            "WeixinJSBridgeReady",
            onBridgeReady,
            false
          );
        } else if (document.attachEvent) {
          document.attachEvent("WeixinJSBridgeReady", onBridgeReady);
          document.attachEvent("onWeixinJSBridgeReady", onBridgeReady);
        }
      } else {
        onBridgeReady();
      }
    },
    orderBtn() {
      if (this.clinicFeeDetail.yblb == "0" && this.radio == "3") {
        this.$dialog.alert({
          message: "当前处方是自费处方，无法医保支付，如需更改请咨询医生"
        });
      } else {
        this.order();
      }
    }
  }
};
</script>

<style lang="less" scope>
.popup {
  .con {
    padding: 10px 30px;
    font-size: 32px;
  }

  button {
    width: 100%;
    background-color: #1cd66c;
    color: #eee;
  }
}

.outpatient_detail_container .header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: white;
}

.outpatient_detail_container .header .info {
  font-size: 30px;
  margin-left: 30px;
  border-bottom: 1px solid skyblue;
  padding: 20px 0;
}

.outpatient_detail_container .header .info div {
  padding: 10px 0;
}

.outpatient_detail_container .header .cost {
  padding-left: 30px;
  font-size: 35px;
  border-bottom: 2px solid skyblue;
}

.outpatient_detail_container .header .cost div {
  margin: 20px 0;
}

.outpatient_detail_container .detail {
  padding-bottom: 100px;
}

.outpatient_detail_container .detail .title {
  padding: 20px 0;
  padding-left: 30px;
  font-size: 40px;
  border-bottom: 1px solid #f5f5f5;
}

.outpatient_detail_container .detail .list {
  padding-bottom: 80px;
}

.outpatient_detail_container .van-cell {
  border-bottom: 1px solid #f5f5f5;
}

.point {
  .point-title {
    font-size: 33px;
  }

  color: #1f2430;
  background-color: #fff;

  .user {
    color: #0852b8;
  }

  .van-cell__title {
    font-size: 30px;
    // padding-left: 50px;
  }

  .sex {
    font-size: 35px;
    padding: 0.26667rem 0.42667rem;
  }
}

.outpatient_detail_container .van-cell__title,
.outpatient_detail_container .van-cell__value,
.outpatient_detail_container .van-cell__label {
  font-size: 30px !important;
}

.outpatient_detail_container .van-cell__label {
  line-height: 70px;
}

.outpatient_detail_container .btn {
  position: fixed;
  left: 0;
  bottom: 60px;
  z-index: 1;
  width: 100%;
  font-size: 40px;
  height: 100px;
}
</style>
